$col-light-grey: #f4f4f4;
$col-light-grey: #e4e4e4;
$col-mid-grey: #cfcfcf;
/*$col-yellow: #feea00;*/
$col-yellow: #efe78d;
$col-green: #3cb83c;
$col-blue: #3c4fb8;

// ------------------------------------------------------------------------

html {
  scroll-behavior: auto !important;
}

body,
input,
textarea,
select {
	font-size: 20px !important;
}

* {
	scrollbar-width: auto;
	scrollbar-color: #feea00 #000000;
}

*::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

*::-webkit-scrollbar-track {
	background: #000000;
}

*::-webkit-scrollbar-thumb {
	background-color: #feea00;
	border-radius: 0px;
	border: 2px solid #000000;
}

html, body {
	width: 100%;
	height: 100%;
	background: $col-light-grey;
}

* {
	font-family: Arial, sans-serif;
	transition: none !important; // Turn off all animation
}

main {
	position: relative;

	& section {
		padding: 20px;
		background-color: #fff;

		&:not(:last-child) {
			margin-bottom: 20px;
		}
	}
}

.warning {
	background: red;
	color: #fff;
	font-size: 30px;
	padding: 20px;
	margin-bottom: 20px;

	& .fa-solid {
		margin-right: 10px;
	}
}

.info {
	background: #f6f2c4;
	color: #948b27;
	font-size: 30px;
	padding: 20px;

	& .fa-solid {
		margin-right: 10px;
	}
}

.top-nav {
	background: #000;
	color: #fff;
	height: 50px;
	padding: 10px;
	display: flex;
	align-items: center;

	& .logo-container {
		height: calc(100% + 20px);
		margin: -10px 10px -10px -10px;
	}

	& img {
		height: 100%;
	}

	& .home-btn {
		margin-right: 10px;
	}

	& .debug-menu {
		margin-left: auto;

		& .btn {
			color: #fff !important;
		}
	}
}

main {
	padding: 20px;
	min-height: calc(100vh - 50px);
}

.btn {
	border-radius: 0px;
	border: none;
	outline: none;
	box-shadow: none;
	font-size: 20px;
}

.btn-primary {
	background-color: $col-green;// $col-yellow;
	color: #ffffff;
	//color: #000;

	&:hover,
	&:active,
	&:focus {
		background-color: $col-green;//$col-yellow;
		color: #ffffff;
	}
}

.btn-secondary {
	background-color: #000;
	color: #fff;

	&:hover,
	&:active,
	&:focus {
		background-color: #000;
		color: $col-yellow;
	}
}

.btn-tertiary {
	background-color: #fff;
	border: 2px solid #ccc;

	&:hover,
	&:focus,
	&:focus-visible,
	&:active,
	&.show {
		border-color: #000 !important;
		color: #000 !important;
	}
}

.section-nav {
	background-color: $col-light-grey;
	margin: -20px -20px 0px -20px;
	padding: 20px 20px 0px 20px;
	position: sticky;
	top: -1px;
	z-index: 1000;
	font-size: 20px;

	& .nav-item {
		&:not(:last-child) {
			& .nav-link {
				margin-right: 10px;
			}
		}
	}

	& .nav-link {
		border: none !important;
		background-color: #eaeaea !important;

		&.active {
			background-color: #ffffff !important;
		}

		&:hover {
			/*color: $col-yellow !important;*/
			color: #000 !important;
		}

		& .fa-solid,
		& .fa-regular {
			margin-right: 10px;
		}
	}
}

.nav-pills {

	& .nav-item {

		&:not(:last-child) {
			margin-right: 5px;
		}

		& .nav-link {
			border: 2px solid $col-yellow;
			background-color: #ffffff;
			color: #000;
			border-radius: 0px;

			&.active {
				background-color: $col-yellow;
				border-color: $col-yellow;
				color: #000;
			}

			&:hover {
				background-color: $col-yellow;
				border-color: $col-yellow;
				color: #fff;
			}
		}
	}
}

.empty-text {
	display: flex;
	//flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: #aaa;
	font-size: 24px;
}

.form-group {
	margin-bottom: 1rem;

	& label {
		display: flex;
		align-items: center;

		& .btn {
			min-width: 24px;
			max-height: 24px;
			font-size: 75%;
		}
	}

	& .search {
		display: block;

		& .search-input-container {
			position: relative;

			& button {
				position: absolute;
				top: 5px;
				right: 5px;
				height: 28px;
				padding: 0px 5px;
			}
		}

		& .no-results {
			text-align: center;
			padding: 10px;
			color: #aaa;
		}

		& .results {
			outline: 2px solid $col-yellow;
			top: 40px;
		}

		& .search-results-button {
			padding: 8px;

			& button {
				display: block;
				width: 100%;
			}
		}
	}
}

.form-label {
	font-weight: bold;

	& .btn {
		padding: 2px 8px;
	}
}

.form-control {
	border-radius: 0px;
}

fieldset {
	margin-bottom: -1rem;
}

h2 {
	background-color: $col-yellow;
	color: #000;
	font-size: 20px;
	border-bottom: 2px solid $col-yellow;
	margin-bottom: 20px;
	padding: 10px 10px;
	display: flex;

	& .btn {
		background-color: #fff;
		color: #000;
		margin: -2px;
		padding: 2px 8px;
		font-size: 16px;

		&:hover {
			outline: 2px solid black;
		}
	}
}

h3 {
	background-color: $col-yellow;
	color: #000;
	font-size: 20px;
	margin-bottom: 10px;
	margin-top: 10px;
	padding: 5px 5px;
	/*font-weight: bold;*/
}

#root.not-logged-in {
	background: $col-mid-grey;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	& img {
		display: block;
		margin: 0px auto 20px auto;
		max-width: 100%;
	}

	& .panel {
		width: 350px;
		max-width: 100%;
		background-color: #000;
		color: #fff;
		padding: 30px;
		border-top: 20px solid $col-yellow;
	}

	& .btn {
		width: 100%;
	}

}

.spinner-border {
	&.inline {
		width: 1em;
		height: 1em;
		border-width: 0.15em;
	}

	&:not(.inline) {
		display: block;
		margin: auto;
	}
}

// Hide arrows on input[type=number]
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type=number] {
	-moz-appearance: textfield;
}

.dropdown-menu {
	border-radius: 0px;
}

tr:hover .floating-controls .btns {
	display: block;
}

.modal-content {
	border-radius: 0px;
	border: none;

	& .btn-close {
		border-radius: 0px;
		border: none;
	}
}

.checkbox-label {
	display: inline-flex;
	align-items: center;

	& input {
		width: 24px;
		height: 24px;
		margin-right: 10px;
	}
}

.modal-backdrop ~ .modal-backdrop {
	z-index: 1055;
}

input[type=color] {
	padding: 0px;
}

.error-boundary {
	padding: 20px;
	border: 2px solid red;
	height: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;

	& h1 {
		text-align: center;
		color: red;
	}

	& h2 {
		justify-content: center;
		background-color: transparent;
		border: none !important;
	}
}

table {
	& .empty-text {
		font-size: 16px;
	}
}

.button-panel {
	display: flex;

	& .left {
		& .btn {
			margin-right: 20px;
		}
	}

	& .right {
		margin-left: auto;
	}
}

.appts-table {
	width: 100%;
	border-collapse: collapse;

	&.overdue {
		color: red;
	}

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	& .header {
		& td {
			background-color: $col-light-grey;
			font-weight: bold;
		}

		& div {
			display: flex;
			align-items: center;

			& .map-btn {
				margin-left: auto;
			}
		}

		& .map-btn {
			float: right;
		}
	}

	& tr.appt {
		cursor: pointer;
	}

	& tr.has-cowl td {
		color: blue;
	}

	& th,
	& td {
		padding: 10px;
		border: 1px solid $col-light-grey;
		vertical-align: top;
	}

	& .time-col {
		width: 13%;
		min-width: 180px;
	}

	& .appt-time {
		display: flex;
		justify-content: center;

		& .fa-regular {
			font-size: 30px;
			margin-right: 15px;
			margin-top: 4px;
		}

		& .time-duration {
		}

		& .time {
			font-size: 24px;
		}

		& .duration {
			/*font-size:*/
		}
	}

	& .customer-col {
		& .map-btn {
			float: right;
		}
	}

	& .phone-col {
		width: 15%;

		& .btn {
			display: block;

			&:not(:last-child) {
				margin-bottom: 10px;
			}
		}
	}

	& .job-col {
		width: 15%;
	}

	& .customer-name,
	& .non-booking-appt-type-name {
		font-weight: bold;
	}

	& .booked-date {
		margin-top: 10px;
		//color: $col-mid-grey
		font-style: italic;
	}

	& .appt-notes {
		margin-top: 10px;
	}
}

.appt-page {

	& .property-address {
		position: relative;
		margin-bottom: 20px;
		//text-align: center;
		font-size: 24px;

		& .btn {
			float: right;
		}
	}

}

.bottom-nav {
	display: flex;

	& .left {
	}

	& .right {
		margin-left: auto;
	}
}

.appt-jobs {

	& .chimney {
		&:not(:last-child) {
			margin-bottom: 20px;
		}

		& h2 {
			position: sticky;
			top: 58px;
			margin: -20px -20px 0px -20px;
			padding: 20px;
			background-color: #fff;
			border: none;
			z-index: 500;

			& > span {
				background-color: $col-yellow;
				padding: 10px;
				display: block;
				width: 100%;
			}

			& .edit-chimney-btn {
				float: right;
			}
		}

		& .chimney-service {
			display: flex;
			align-items: stretch;

			&.active {
				& .service-type {
					background: $col-yellow;
				}
			}

			&:not(:last-child) {
				margin-bottom: 20px;
				padding-bottom: 20px;
				border-bottom: 2px solid $col-light-grey;
			}
		}

		& .service-type {
			background: $col-light-grey;
			padding: 20px;
			width: 130px;
			margin-right: 20px;
			text-align: center;
			font-size: 20px;
			font-weight: bold;

			& span {
				position: sticky;
				top: 160px;
			}
		}

		& .service-results {
			flex: 1;

			& .empty-text {
				height: 100%;
			}
		}
	}

	& .delete-service-btn {
		float: right;
	}

	& .add-service-btn {
		margin-left: 10px;
	}
}

.button-options {
	margin-right: -10px;
	margin-bottom: -10px;

	& .btn {
		margin-right: 10px;
		margin-bottom: 10px;
		border: 2px solid $col-light-grey;

		&.active {
			background-color: $col-blue;
			color: #fff;
			border-color: black;
		}

		& .fa-regular {
			margin-right: 5px;
		}
	}

	&.vertical-stack {
		margin-right: 0px;

		& .btn {
			width: 100%;
			text-align: left;
		}
	}
}

.appt-products-table {

	& .name-col {
	}

	& .unit-price-col,
	& .quantity-col,
	& .total-price-col {
		text-align: center;

		& input {
			text-align: center;
		}
	}

	& .unit-price-col {
		width: 120px;
	}

	& .quantity-col {
		width: 120px;
	}

	& .total-price-col {
		width: 120px;
	}

	& td.total-price-col {
		padding-top: 12px;
		padding-bottom: 12px;
	}

	& .actions-col {
		width: 55px;
		text-align: center;
	}

	& tfoot {
		& td {
			font-weight: bold;
		}
	}
}

.appt-summary-table {

	& .job-name {
		font-weight: bold;
	}

	& .details-col {
		width: 65%;

		& .product {
			&:not(:last-child) {
				margin-bottom: 10px;
			}

			& .product-name {
				font-weight: bold;
			}
		}
	}

	& .price-col {
		width: 120px;
		text-align: center;

		& input {
			text-align: center;
		}
	}

	& .result-field {
		&:not(:last-child) {
			margin-bottom: 10px;
		}

		& .field-name {
			font-weight: bold;
			margin-right: 5px;
		}
	}

	& tfoot {
		font-weight: bold;
	}
}

.day-sheet-notes {
	padding: 5px;

	& .inner {
		padding: 10px;
		border: 2px dashed red;

		& > div {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}

	}

	& h2 {
		width: 100%;
	}

	& .fa-regular {
		font-size: 30px;
		margin-right: 15px;
	}
}

.appt-summary {

	& .financial-summary {
		padding: 10px;
		background-color: #fff;
		border: 2px solid black;
		font-size: 20px;

		& .field {
			display: flex;

			&:not(:last-child) {
				margin-bottom: 10px;
			}

			& .field-name {
				padding: 11px;
				flex: 1;
			}

			& .field-value {
				padding: 11px;
				width: 120px;
				text-align: center;
				background-color: $col-yellow;
				font-weight: bold;
			}

			&.outstanding-balance {
				& .field-value {
					background-color: red;
					color: #fff;
				}
			}

			&.balance-paid {
				& .field-value {
					background-color: $col-green;
					color: #fff;
				}
			}
		}
	}
}

.appt-payments-table {

	& .date-col {
		width: 150px;
	}

	& .amount-col {
		width: 120px;
	}

	& .deposit-col {
	}

	& .method-col {
	}

	& .actions-col {
		width: 50px;
	}
}


.appt-signature {
	min-height: calc(100vh - 278px);

	& .signature-area {
		display: flex;
		min-height: calc(100vh - 332px);
	}

	& .signature-area-left {
		flex: 1;
		margin-right: 20px;
		display: flex;
		flex-direction: column;
		min-height: 100%;

		& .bottom {
			margin-top: auto;
		}
	}

	& .signature-area-right {
		width: 500px;

		& .declaration {
			margin-bottom: 20px;
		}

		& .signature-canvas {
			display: block;
			margin: auto;
			background-color: #eee;
			border: 2px solid #ccc;
		}

		& .please-sign {
			margin-top: 5px;
			font-style: italic;
			color: #808080;
			text-align: center;
		}
	}
}

.next-appt-modal {
	& .modal-dialog {
		width: 800px;
		max-width: 100%;
	}

	& .field {
		font-size: 30px;
		display: flex;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		& .field-name {
			font-weight: bold;
			display: inline-block;
			min-width: 170px;
		}

		& .field-value {
			display: inline;
		}
	}
}

.add-jobs-checkbox { 
	margin-bottom: 20px;
}

.spinning-icon {
	animation: spin 1s linear infinite;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.warning-text {
	color: red;
}

.suggested-appt-date {
	& .suggested-appt-date-title {
		font-weight: bold;
		padding-top: 20px;
		padding-bottom: 20px;
		font-size: 20px;
	}

	& .suggested-appts {
		padding-left: 20px;
	}
}

.mobile-only {
	display: none;
}






@media (max-width: 767px) {

	.top-nav {
		& .property-address {
			display: none;
		}
	}

	.mobile-only {
		display: block;

		& .appt-time {
			justify-content: flex-start !important;
			margin-bottom: 15px;
		}
	}

	.button-panel {
		display: block;
		margin-bottom: -15px;

		& .btn {
			margin-bottom: 15px;
			display: block;
			width: 100%;
		}
	}

	.appts-table {

		& thead {
			display: none;
		}

		& tr {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;

			& .map-btn {
				margin-bottom: 15px;
			}

			& .time-col {
				display: none;
			}

			& .customer-col {
				width: 100%;
			}

			& .phone-col,
			& .job-col {
				width: 50%;
			}
		}
	}
}

